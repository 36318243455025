/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/pickadate@3.6.3/lib/compressed/picker.js
 * - /npm/pickadate@3.6.3/lib/compressed/picker.date.js
 * - /npm/pickadate@3.6.3/lib/compressed/picker.time.js
 * - /npm/cookieconsent@3.1.0/build/cookieconsent.min.js
 * - /npm/jquery-lazyload@1.9.7/jquery.lazyload.min.js
 * - /npm/screenfull@4.2.0/dist/screenfull.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
